<template>
  <div>
    <div class="compont_p">
      物流详情
      <div class="btn flex_c_c" @click="backOrder">返回上级</div>
    </div>
    <div class="table-top">
      <el-descriptions class="margin-top" title="订单物流信息" :column="3" :size="size" border>
        <!-- <template slot="extra">
          <el-button type="primary" size="small">操作</el-button>
        </template> -->
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i>
            订单编号
          </template>
          {{ logisticsData.orderno }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-box"></i>
            承运公司
          </template>
          {{ logisticsData.logistic_name }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-edit-outline"></i>
            物流编号
          </template>
          {{ logisticsData.logistic_no }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-receiving"></i>
            物流状态
          </template>
          <el-tag size="small">{{ logisticsData.logistic_status }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile"></i>
            服务电话
          </template>
          {{ logisticsData.logistic_tel }}
        </el-descriptions-item>
      </el-descriptions>
    </div>
    <div class="block step-box">
      <el-timeline>
        <el-timeline-item v-for="(item, index) in logisticsData.logistic" :key="index" placement="top">
          <el-card>
            <h4>{{ item.content }}</h4>
            <p>{{ item.time_str }}</p>
          </el-card>
        </el-timeline-item>
        <!-- <el-timeline-item timestamp="2018/4/3" placement="top">
          <el-card>
            <h4>更新 Github 模板</h4>
            <p>王小虎 提交于 2018/4/3 20:46</p>
          </el-card>
        </el-timeline-item> -->
        <!-- <el-timeline-item timestamp="2018/4/2" placement="top">
          <el-card>
            <h4>更新 Github 模板</h4>
            <p>王小虎 提交于 2018/4/2 20:46</p>
          </el-card>
        </el-timeline-item> -->
      </el-timeline>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      logisticsData: {},
    };
  },
  created() {
    this.id = this.$route.query.id;
    this.orderno = this.$route.query.orderno;
    this.type = this.$route.query.type;
    this.getLogisticsData();
    // console.log("订单详情---", id, orderno);
  },
  methods: {
    getLogisticsData() {
      this.$api("account.getLogistics", { id: this.id, orderno: this.orderno }).then((res) => {
        this.logisticsData = res.data;
      });
    },
    backOrder() {
      if (this.type == "DD") {
        this.$router.push({
          path: "/index/subRouter/order",
          query: { type: 101, current: 4 },
        });
      }
      if (this.type == "TK") {
        this.$router.push({
          path: "/index/subRouter/returnGoods",
        });
      }
    },
  },
};
</script>
<style lang="less" scoped>
.compont_p {
  position: relative;
  .btn {
    position: absolute;
    left: 100px;
    top: 0;
    width: 76px;
    height: 30px;
    line-height: 30px;
    border-radius: 4px;
    font-size: 14px;
    font-weight: 400;
    color: #ffffff;
    cursor: pointer;
    background: @themeColor;
  }
}
.table-top {
  margin-top: 20px;
}
.step-box {
  margin-top: 20px;
}
</style>
